import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useMemo, useState } from "react";
import { Stripe, StripeElementLocale} from '@stripe/stripe-js/types/stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import PaymentButtons from "../components/PaymentButtons";
import { dispatch } from "src/workers/common-worker"
import { InitPaymentButtonsRequest, InitPaymentButtonsResponse } from "../workers/types";
import config from "src/config";
import { Nullable } from "src/workers/types";
import { ValidationError } from "class-validator";
import { PaymentButtons as Buttons } from "payments-ui-components/components/types";
import { useSearchParams } from "react-router-dom";
import { ButtonsComponentPage } from "../components/Types";
import { Assets, Branding, PresentationSettings } from "src/domain/PresentationSettings";

export default function PaymentButtonsPage() {
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>|null>(null);
    const paymentsServiceUrl: string =  config.apis.paymentServiceUrl;
    const tokenMediatorUrl: string =  config.apis.tokenMediatorUrl;
    const [searchParams] = useSearchParams()
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const [stripeInitialized, setStripeInitialized] = useState<boolean>();
    const [brandAllowedPaymentButtons, setBrandAllowedPaymentButtons] = useState<Buttons[]>();
    const [allowedPaymentButtons, setAllowedPaymentButtons] = useState<Buttons[]>();
    const [presentationSettings, setPresentationSettings] = useState<PresentationSettings>();
    const [account, setAccount] = useState<string>();
    const [amount, setAmount] = useState<number>()
    const [currency, setCurrency] = useState<string>()
    const [description, setDescription] = useState<string>()
    
    const worker: Worker = useMemo(
        () => new Worker(new URL("src/features/fast_payment_buttons/workers/init-payment-buttons.ts", import.meta.url)),
        []
    );

    useEffect(() => {
        document.body.style.overflow = "hidden";
        const tenantUrlParam = searchParams.get("tenant");
        const brandUrlParam = searchParams.get("brand");
        if(tenantUrlParam === null && brandUrlParam === null){
            return;
        }

        const pData = searchParams.get("data");
        if(!pData){
            return;
        }
        const paymentParams = atob(pData);
        const paymentQueryParams = new URLSearchParams(paymentParams);
        const amountUrlParam = paymentQueryParams.get("amount");
        const currencyUrlParam = paymentQueryParams.get("currency");
        const descriptionUrlParam = paymentQueryParams.get("description");
        const allowedPaymentButtonsParam = paymentQueryParams.get("allowedPaymentButtons");

        setAmount(Number(amountUrlParam));
        setCurrency(currencyUrlParam!);
        setDescription(decodeURIComponent(descriptionUrlParam!));
        const buttonsAvailable = allowedPaymentButtonsParam?.split(",") as Buttons[];
        setAllowedPaymentButtons(buttonsAvailable);

        if (Worker) {
            const request: InitPaymentButtonsRequest = {
                tokenMediatorUrl: tokenMediatorUrl,
                paymentsServiceUrl: paymentsServiceUrl,
                tenantId: tenantUrlParam!,
                brandId: brandUrlParam!
            };

            dispatch<InitPaymentButtonsRequest, InitPaymentButtonsResponse>(worker, request)
                .then((response: Nullable<InitPaymentButtonsResponse>) => buildPage(response))
                .then((errors) => setValidationErrors(errors));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [worker]);


    const mapBrandInstrumentTypes = (types: string[]) => {
        let brandInstrumentTypes: Buttons[] = [];
        types.forEach((s) => {
            if(s === "card"){
                brandInstrumentTypes.push(Buttons.Wallet);
                return;
            }

            if(s === "zahl_einfach"){
                brandInstrumentTypes.push(Buttons.ZahlEinfach);
                return;
            }
        });

        return brandInstrumentTypes;
    }

    function mapAccount(responseAccount){
        if(responseAccount === "UK"){
            return "GB";
        }

        return responseAccount;
    }

    async function buildPage(response) : Promise<ValidationError[]> {
        setStripePromise(loadStripe(config.gateway.apiKeys[response.gateway_account], {locale: ("en" as StripeElementLocale)}));
        setStripeInitialized(true);
        setBrandAllowedPaymentButtons(mapBrandInstrumentTypes(response.instrument_types));
        setPresentationSettings(mapSettings(response));
        setAccount(mapAccount(response.gateway_account));
        
        return [];
    }

    function mapSettings(data){
        let presentationSettings = new PresentationSettings();
        

        let branding = new Branding();
        branding.button_color = data.presentation_settings.branding.button_color;
        presentationSettings.branding = branding;
        return presentationSettings;
    }

    let buttonsComponentPage = {
        brandAllowedPaymentButtons: brandAllowedPaymentButtons,
        allowedPaymentButtons: allowedPaymentButtons,
        presentationSettings: presentationSettings,
        account: account,
        amount: amount,
        currency: currency,
        description: description
    } as ButtonsComponentPage

    return (
        <div>
            {stripeInitialized && buttonsComponentPage &&(
                <Elements stripe={stripePromise}>
                    <PaymentButtons {...buttonsComponentPage}></PaymentButtons>
                </Elements>
            )}
        </div>
    );
}

