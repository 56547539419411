import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import {HostedSession} from "src/domain/HostedSession";
import "src/App.css";
import {useTranslation} from 'react-i18next';
import '@payrails/web-sdk/payrails-styles.css';
import Payrails, {InitOptions, PayrailsEnvironment} from "@payrails/web-sdk";
import config from "src/config";

export default function CheckoutFormPayrails({hostedSession} : {hostedSession : HostedSession}) {
    const { t, i18n } = useTranslation();
    const { param } = useParams();
    const navigate = useNavigate();

    const StagingEnv: string = "staging";

    const logo = hostedSession.presentation_settings.assets.logo.indexOf('://') > 0 ?
        hostedSession.presentation_settings.assets.logo : require(`src/assets/${hostedSession.presentation_settings.assets.logo}`);

    useEffect(() => {
        const loadSDKConfig = async () => {

            let sessionData = {
                data: hostedSession.client_secret,
            }

            let options = {
                environment: (config.paywallEnv === StagingEnv || hostedSession.test_mode) ? PayrailsEnvironment.TEST : PayrailsEnvironment.PRODUCTION,
            }

            const payrailsClient = Payrails.Payrails.init(sessionData as InitOptions, options);

            const dropin = payrailsClient.dropin({
                styles:{
                    element: {
                        base: {
                            boxSizing: 'border-box'
                        }
                    },
                    authFailed: {
                        maxWidth: '100%'
                    },
                },
                paymentMethodsConfiguration: {
                    cards: {
                        showCardHolderName: true,
                        showExistingCards: true,
                        showStoreInstrumentCheckbox: false,
                        alwaysStoreInstrument: true,
                    },
                },
                events: {
                    // @ts-ignore
                    onAuthorizeSuccess: () => {
                        window.location.replace(hostedSession.success_url);
                    },
                },
            });

            dropin.mount("#dropin-container");
        }

        loadSDKConfig();
        loadFavicon(hostedSession);
        setBackgroundColor(hostedSession);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (hostedSession === null || hostedSession === undefined) {
        return <p>Loading..</p>;
    }

    const loadFavicon = (hostedSession : HostedSession) => {
        const link = document.createElement('link');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        document.head.appendChild(link);

        if (hostedSession.presentation_settings.assets.favicon.indexOf("://") > 0) {
            (link as HTMLLinkElement).href = hostedSession.presentation_settings.assets.favicon;
        } else {
            (link as HTMLLinkElement).href = `/${hostedSession.presentation_settings.assets.favicon}-32x32.png`;
        }
    }

    const setBackgroundColor = (hostedSession: HostedSession) => {
        document.body.style.backgroundColor = hostedSession.presentation_settings.branding.background_color;
    }

    return (
        <Grid item xs={12} sm={6} md={4}>
            <div>
                <div id="dropin-container"/>
            </div>
            <div className="allow-text" style={{marginTop: "20px"}}>
                {/* to be shown only when it makes sense for the card to be used in off session */}
                {t("checkoutCardAdvice")}
            </div>
        </Grid>
    );
}