import * as React from 'react'

import {
    Routes,
    Route
} from 'react-router-dom'
import ErrorBoundary from './ErrorBoundary'

import Confirmation from 'src/features/payment_pages/pages/Confirmation'
import Checkout from "src/features/payment_pages/pages/Checkout";
import Complete from "src/features/payment_pages/pages/Complete";
import Setup from "src/features/setup_pages/pages/Setup";
import InvoiceRoutes from 'src/features/enterprise/intents/pages';
import InstrumentRoutes from 'src/features/enterprise/instruments/pages';
import Login from 'src/features/enterprise/components/Login';
import PaymentButtonsPage from 'src/features/fast_payment_buttons/pages/PaymentButtonsPage';
import Test from 'src/components/Test';
import ZahlEinfachRoutes from 'src/features/pekunia/pages';
import { ThemeContextProvider } from 'src/theme/ThemeContext';

const Routing: React.FC<{}> = () => {
    return (
        <ErrorBoundary>
            <Routes>
                <Route path={'/login'} element={<Login />} />
                <Route path={'/confirmation'} element={<Confirmation />} />
                <Route path={'/checkout/:param'} element={ <ThemeContextProvider><Checkout/></ThemeContextProvider> }/>
                <Route path={'/complete'} element={ <Complete/> }/>
                <Route path={'/setup/:param'} element={ <ThemeContextProvider><Setup/></ThemeContextProvider> } />
                <Route path={'/payment-buttons'} element={ <PaymentButtonsPage/> } />
                <Route path={'/test'} element={ <Test/> }/>
                {InvoiceRoutes}
                {InstrumentRoutes}
                {ZahlEinfachRoutes}
            </Routes>
        </ErrorBoundary>
    )
}

export { Routing }
